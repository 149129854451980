var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"flex flex-col justify-center",on:{"submit":function($event){$event.preventDefault();return _vm.update()}}},[_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"flex justify-center mb-5 col-span-2"},[_c('div',{staticClass:"relative"},[_c('img',{staticClass:"rounded-full w-32 mx-auto",attrs:{"src":_vm.user.account.avatar_url || require('@/assets/images/user.png'),"alt":"Avatar"}}),_c('span',{staticClass:"absolute bg-indigo-900 text-white shadow-md rounded-full p-2 right-1 bottom-2 cursor-pointer"},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"}}),_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 13a3 3 0 11-6 0 3 3 0 016 0z"}})])])])]),_c('div',{staticClass:"text-xl text-indigo-900 col-span-2 pb-5 font-semibold"},[_vm._v("Personal Profile")]),_c('ValidationProvider',{attrs:{"tag":"div","name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("First name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.first_name),expression:"user.account.first_name"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "first_name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"middle name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Middle name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.middle_name),expression:"user.account.middle_name"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.middle_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "middle_name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Last name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.last_name),expression:"user.account.last_name"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "last_name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.username),expression:"user.account.username"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "username", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"email","spellcheck":"false"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-gray-700 font-semibold"},[_vm._v("Your phone number")]),_c('MazPhoneNumberInput',{staticClass:"mt-2",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"default-country-code":"NG"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('span',{staticClass:"text-sm  text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr',{staticClass:"border-t border-gray-200 border-dotted col-span-2 my-4"}),_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"text-xl pb-4 text-indigo-900 col-span-2 font-semibold"},[_vm._v("Contact Address")]),_c('ValidationProvider',{staticClass:"cols-span-2 relative",attrs:{"tag":"div","name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Country")]),_c('og-custom-select',{attrs:{"data":_vm.countries,"showImage":false},on:{"valueSelect":_vm.onCountrySelect},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"cols-span-2 relative",attrs:{"tag":"div","name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("State")]),_c('og-custom-select',{attrs:{"data":_vm.states,"showImage":false},on:{"valueSelect":_vm.onStateSelect},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.city),expression:"user.account.city"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "city", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"street name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Street name & number")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.address),expression:"user.account.address"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "address", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr',{staticClass:"border-t border-gray-200 border-dotted col-span-2 my-4"}),_c('div',{staticClass:"grid grid-cols-2 gap-5 bg-white rounded-md border p-10 "},[_c('div',{staticClass:"text-xl pb-4 text-indigo-900 col-span-2 font-semibold"},[_vm._v("Business Profile")]),_c('ValidationProvider',{staticClass:"col-span-2 relative",attrs:{"tag":"div","name":"business name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Business Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.business_name),expression:"user.account.business_name"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.business_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "business_name", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"cols-span-2 relative",attrs:{"tag":"div","name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Country")]),_c('og-custom-select',{attrs:{"data":_vm.countries,"showImage":false},on:{"valueSelect":_vm.onCountrySelect},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"cols-span-2 relative",attrs:{"tag":"div","name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("State")]),_c('og-custom-select',{attrs:{"data":_vm.states,"showImage":false},on:{"valueSelect":_vm.onStateSelect},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.city),expression:"user.account.city"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "city", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-span-2",attrs:{"tag":"div","name":"street name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block text-gray-600 font-semibold leading-none mb-3 cursor-pointer"},[_vm._v("Location of business (Street name & number)")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.account.address),expression:"user.account.address"}],staticClass:"py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading",class:errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 ',attrs:{"type":"text","spellcheck":"false"},domProps:{"value":(_vm.user.account.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user.account, "address", $event.target.value)}}}),_c('span',{staticClass:"text-sm text-red-600 font-semibold"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-8 flex justify-end"},[_c('button',{staticClass:"leading-4 inline-flex items-center cursor-pointer transition \n               ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md \n               focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 \n               hover:bg-indigo-800 mt-1.5",attrs:{"type":"submit","disabled":invalid}},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"animate-spin h-5 w-5 mr-3 ...",attrs:{"viewBox":"0 0 24 24"}}),_vm._v(" Update Information ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }