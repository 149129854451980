<template>
   <div>
      <ValidationObserver v-slot="{ invalid }" tag="div" class="">
         <form class="flex flex-col justify-center" @submit.prevent="update()">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="flex justify-center mb-5 col-span-2">
                  <div class="relative">
                     <img :src="user.account.avatar_url || require('@/assets/images/user.png')" class="rounded-full w-32 mx-auto" alt="Avatar" />
                     <span class="absolute bg-indigo-900 text-white shadow-md rounded-full p-2 right-1 bottom-2 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                     </span>
                  </div>
               </div>
               <div class="text-xl text-indigo-900 col-span-2 pb-5 font-semibold">Personal Profile</div>
               <ValidationProvider tag="div" name="first name" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">First name</label>
                  <input type="text" v-model="user.account.first_name"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="middle name" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Middle name</label>
                  <input type="text" v-model="user.account.middle_name"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="last name" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Last name</label>
                  <input type="text" v-model="user.account.last_name"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="username" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Username</label>
                  <input type="text" v-model="user.account.username"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="email" rules="required|email" class="col-span-2" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Email</label>
                  <input type="email" v-model="user.email"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="phone number" rules="required" class="col-span-2" v-slot="{ errors }">
                  <label class="text-gray-700 font-semibold">Your phone number</label>
                  <MazPhoneNumberInput v-model="user.phone" default-country-code="NG" class="mt-2" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                  <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <hr class="border-t border-gray-200 border-dotted col-span-2 my-4">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Contact Address</div>
               <ValidationProvider tag="div" name="country" class="cols-span-2 relative" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Country</label>
                  <og-custom-select :data="countries" :showImage="false" v-model="form.country" v-on:valueSelect="onCountrySelect"></og-custom-select>
                  <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="state" class="cols-span-2 relative" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">State</label>
                  <og-custom-select :data="states" :showImage="false" v-model="form.state" v-on:valueSelect="onStateSelect"></og-custom-select>
                  <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="city" rules="required" class="col-span-2" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">City</label>
                  <input type="text" v-model="user.account.city"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="street name" rules="required" class="col-span-2" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Street name &amp; number</label>
                  <textarea type="text" v-model="user.account.address"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"></textarea>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <hr class="border-t border-gray-200 border-dotted col-span-2 my-4">
            <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 ">
               <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Business Profile</div>
               <ValidationProvider tag="div" name="business name" class="col-span-2 relative" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business Name</label>
                  <input type="text" v-model="user.account.business_name"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="country" class="cols-span-2 relative" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Country</label>
                  <og-custom-select :data="countries" :showImage="false" v-model="form.country" v-on:valueSelect="onCountrySelect"></og-custom-select>
                  <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="state" class="cols-span-2 relative" rules="required" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">State</label>
                  <og-custom-select :data="states" :showImage="false" v-model="form.state" v-on:valueSelect="onStateSelect"></og-custom-select>
                  <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="city" rules="required" class="col-span-2" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">City</label>
                  <input type="text" v-model="user.account.city"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"/>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
               <ValidationProvider tag="div" name="street name" rules="required" class="col-span-2" v-slot="{ errors }">
                  <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Location of business (Street name &amp; number)</label>
                  <textarea type="text" v-model="user.account.address"
                     class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading"
                     :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                     spellcheck="false"></textarea>
                     <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
               </ValidationProvider>
            </div>
            <div class="mt-8 flex justify-end">
               <button type="submit" class="leading-4 inline-flex items-center cursor-pointer transition 
                  ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md 
                  focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-4 md:px-6 bg-indigo-900 
                  hover:bg-indigo-800 mt-1.5" :disabled="invalid">
                  <svg v-show="isLoading" class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                     <!-- ... -->
                  </svg> Update Information
               </button>
            </div>
         </form>
      </ValidationObserver>
   </div>
</template>
<script>
   import { mapGetters } from "vuex"
   import OgCustomSelect from '@/components/og-custom-select.vue'
   export default {
      name: "Profile",

      components: {
         OgCustomSelect
      },

      data() {
         return {
            isLoading: false,
            countries: [],
            states: [],
            form: {
               country: { id: 1, name: "Select country", value: '' },
               state: { id: 1, name: "Select state", value: '' },
            }
         }
      },

      methods: {
         async update() {
            this.isLoading = true 
            try{
               let res = await this.$store.dispatch('auth/update_information', this.user)
               this.$toast.success(res.message)
               this.isLoading = false
            } catch (error) {
               this.isLoading = false
               this.$toast.error(error.response.message)
            }
         },
         
         onCountrySelect(value) {
            if(this.form.country == '') {
               this.form.country = this.user.account.country
            }

            this.form.country = value
         },

         onStateSelect(value) {
            if(this.form.state == '') {
               this.form.state = this.user.account.state
            }

            this.form.state = value
         },
      },

      computed: {
         ...mapGetters("auth", ["user"]),
      },
   }
</script>